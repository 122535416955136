import React from "react";

// Styling
import "./styling/Navbar.css";

// Images
import logo from "../images/logo.svg";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navItem">
        <a href="#" className="navLink">
          <img src={logo} className="navbarLogo" />
        </a>
      </div>
      <div className="navItem">
        <a href="#whatWeOffer" className="navLink">
          What We Offer
        </a>
      </div>
      <div className="navItem">
        <a href="#ourServices" className="navLink">
          Our Services
        </a>
      </div>
      <div className="navItem">
        <a href="#testimonials" className="navLink">
          Testimonials
        </a>
      </div>
      <div className="navItem">
        <a href="#footer" className="navLink">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Navbar;
