import React from "react";

// Styling
import "./styling/OurServices.css";

// Icons
import { IoIosGitNetwork } from "react-icons/io";
import { FcDataRecovery } from "react-icons/fc";
import { IoShieldHalfOutline } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";

const OurServices = () => {
  return (
    <div className="ourServices" id="ourServices">
      <p className="ourServicesHeading left">Our Services</p>
      <div className="ourServicesPanelContainer left">
        {/* Panel 1 */}
        <div className="ourServicesPanel">
          <IoShieldHalfOutline className="ourServicesImage" />
          <h3 className="ourServicesImageHeading">Virus & Spyware Removal</h3>
        </div>

        {/* Panel 2 */}
        <div className="ourServicesPanel">
          <IoIosGitNetwork className="ourServicesImage" />
          <h3 className="ourServicesImageHeading">Networking</h3>
        </div>

        {/* Panel 3 */}
        <div className="ourServicesPanel">
          <FcDataRecovery className="ourServicesImage" />
          <h3 className="ourServicesImageHeading">Data Recovery</h3>
        </div>

        {/* Panel 4 */}
        <div className="ourServicesPanel">
          <BsGraphUpArrow className="ourServicesImage" />
          <h3 className="ourServicesImageHeading">Digital Marketing</h3>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
