import React from "react";

// Styling
import "./styling/Testimonials.css";

const Testimonials = () => {
  return (
    <div className="testimonials left" id="testimonials">
      <div className="testimonialsHeadingContainer">
        <div className="testimonialsHeading">Testimonials</div>
        <div className="testimonialsHeadingContent">
          See What Our Clients have to Say About Us!
        </div>
      </div>
      <div className="testimonialsContentContainer">
        <div className="testimonialPanel">
          <h3 className="testimonialPanelHeading">
            Solved my problem quickly and at a reasonable price. It was an
            emergenct situation with my son leaving for school and they
            delivered it back on a Sunday for me. Great Service!
          </h3>
          <p className="testimonialPanelContent">- Kathy Price</p>
        </div>
        <div className="testimonialPanel">
          <h3 className="testimonialPanelHeading">
            They are AWESOME!!, EXPIDIENT and PROFESSIONAL! Very happy with the
            Laptop they suggested. We need more like him running small
            businesses as its very personal and more friendly service, THANKYOU
            VERY MUCH!
          </h3>
          <p className="testimonialPanelContent">Beth</p>
        </div>
        <div className="testimonialPanel">
          <h3 className="testimonialPanelHeading">
            My macbook was repaired the same day! These guys know what they are
            doing. Quick and Professional
          </h3>
          <p className="testimonialPanelContent">Ellen Wells</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
