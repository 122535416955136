import React from "react";

// Styling
import "./styling/Footer.css";

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footerHeadingContainer">
        <h1 className="footerHeading">Aquarius Technologies</h1>
        <p className="footerHeadingContent">
          Call Our Office <strong>833-302-2044</strong>
        </p>
      </div>
      <div className="footerInfoContainer">
        <div>1740 Broadway New York City NY 10019</div>
        <div>10AM - 7PM CST</div>
        <div>support@aquariustechnologies.net</div>
      </div>
      <div className="footerCopyrightContainer">
        Copyright © Aquarius Technologies All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
