import React from "react";

// Styling
import "./styling/HeroSection.css";

// Images
import heroImage from "../images/heroImage.png";

const HeroSection = () => {
  return (
    <div className="heroSection" id="heroSection">
      <h1 className="heroSectionHeading left">PC Support Services</h1>
      <h3 className="heroSectionContent left">
        Computer Solutions For Your Home & Business
      </h3>
    </div>
  );
};

export default HeroSection;
