import React from "react";

// Styling
import "./styling/WhatWeOffer.css";

// Images
import img1 from "../images/whatweofferimg1.png";
import img2 from "../images/whatweofferimg2.png";
import img3 from "../images/whatweofferimg3.png";

const WhatWeOffer = () => {
  return (
    <div className="whatWeOffer" id="whatWeOffer">
      <p className="whatWeOfferHeading left">What we Offer</p>
      <div className="panelContainer left">
        {/* Panel 1 */}
        <div className="whatWeOfferPanel">
          <img src={img1} className="whatWeOfferImage" />
          <h3 className="whatWeOfferImageHeading">Desktop Computer Repair</h3>
        </div>

        {/* Panel 2 */}
        <div className="whatWeOfferPanel">
          <img src={img2} className="whatWeOfferImage" />
          <h3 className="whatWeOfferImageHeading">Laptop Repair</h3>
        </div>

        {/* Panel 3 */}
        <div className="whatWeOfferPanel">
          <img src={img3} className="whatWeOfferImage" />
          <h3 className="whatWeOfferImageHeading">Mac Repair</h3>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
