import React from "react";

// Styling
import "./styling/WhyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <div className="whyChooseUs" id="whyChooseUs">
      <div className="whyChooseUsHeadingContainer left">
        <h1 className="whyChooseUsHeading">Why Choose Us</h1>
        <p className="whyChooseUsHeadingContent">
          There are many reasons yous should choose us for your Home or Business
        </p>
      </div>
      <div className="whyChooseUsContentContainer right">
        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">Certified Technicians</h1>
          <p className="whyChooseUsPanelContent">
            We pride ourselves on being an A+ Certified Authorized Computer
            Repair Center
          </p>
        </div>

        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">20+ Years of Experience</h1>
          <p className="whyChooseUsPanelContent">
            Our technicians will give you honest, reliable and professional
            advice
          </p>
        </div>

        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">Quick Turnaround</h1>
          <p className="whyChooseUsPanelContent">
            Most of the services are repaired within 1 day and in some cases
            same day!
          </p>
        </div>

        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">Excellent Reputation</h1>
          <p className="whyChooseUsPanelContent">
            Voted Best of Delco and Talk of the Town
          </p>
        </div>

        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">Free Diagnostics</h1>
          <p className="whyChooseUsPanelContent">
            We will diagnose your computer, provide you with options, and give
            you a price for FREE!
          </p>
        </div>

        <div className="whyChooseUsPanel">
          <h1 className="whyChooseUsPanelHeading">Trustworthy</h1>
          <p className="whyChooseUsPanelContent">
            Trustworthy Our business has been built on customer satisfaction and
            trust
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
