// Styling
import "./App.css";

// Components
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhatWeOffer from "./components/WhatWeOffer";
import OurServices from "./components/OurServices";
import WhyChooseUs from "./components/WhyChooseUs";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
// import Icon from "./components/Icon";

function App() {
  return (
    <div className="app">
      <Navbar />
      <HeroSection />
      <WhatWeOffer />
      <OurServices />
      <WhyChooseUs />
      <Testimonials />
      <Footer />
      {/* <Icon /> */}
    </div>
  );
}

export default App;
